import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExceptionalHoursView } from '../../../models/exceptionalHoursView/exceptional-hours-view.model';
import { Facility } from '../../../models/facility/facility.model';
import { FacilityType } from '../../../models/facility/facilityType.enum';
import { Restaurant } from '../../../models/restaurant/restaurant.model';
import { Role } from '../../../models/role.enum';
import { FacilityService } from '../../../service/facility/facility.service';
import { PersistanceService } from '../../../service/persistance/persistance.service';
import { SlotService } from '../../../service/slot/slot.service';
import { UserService } from '../../../service/user/user.service';
import { CustomSnackbarComponent } from '../../common/custom-snackbar/custom-snackbar.component';
import { EditFacilityModalComponent } from '../edit-facility/edit-facility-modal/edit-facility-modal.component';

@Component({
  selector: 'app-facility',
  templateUrl: './facility.component.html',
  styleUrls: ['./facility.component.scss'],
})
export class FacilityComponent implements OnInit, OnChanges {
  @Input() facilityType: FacilityType;
  @Input() facility: Facility;
  @Input() isSuperParent: boolean;
  // The status of the facility Parent (true if "super" parent)
  @Input() parentIsActive: boolean;
  @Input() useFacilityName: boolean;
  @Input() displayChildrenSection: boolean;

  @Input() numberMyRoomChildrenChange: number;
  @Input() isRushHours: boolean;

  @Input() facilityParentType: FacilityType;
  @Input() facilitiesList: Facility[];
  @Input() exceptionalHoursTabInput: ExceptionalHoursView[];

  // Is switch active button enabled, default true
  @Input() enabledSwitch = true;
  // Specific facility important message information if needed
  @Input() importantInformation: string;

  @Output() changeChildfacilityStatus: EventEmitter<Facility> = new EventEmitter<Facility>();
  @Output() reloadFacilities: EventEmitter<boolean> = new EventEmitter<boolean>();

  restaurant: Restaurant;
  switchLabel: string;
  seeHoursLabel: string;
  seeFacilityLabel: string;
  seeHoursActive: boolean;
  partnerManageOpeningHours: boolean;
  isLAD = false;
  haveExceptional = false;

  NATIONAL_MESSAGE = "En raison d'une mesure nationale, l'activation n'est pas autorisée.";
  NO_PARTNER_MANAGE_OPENING_MESSAGE =
    'Si vous souhaitez paramétrer les jours et horaires de disponibilité de votre partenaire de livraison, veuillez vous rendre directement sur la tablette du partenaire.';

  isUserRestaurantManager = false;
  startDateExceptional: Date;
  endDateExceptional: Date;
  startConfigured = false; // for exceptional date calculation
  endConfigured = false; // for exceptional date calculation

  constructor(
    public matDialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly persistanceService: PersistanceService,
    private readonly facilityService: FacilityService,
    private readonly slotService: SlotService,
    private readonly userService: UserService
  ) {}

  ngOnInit(): void {
    this.checkUserRole();
    this.restaurant = this.persistanceService.get('current_restaurant');

    if (this.exceptionalHoursTabInput && this.exceptionalHoursTabInput.length > 0) {
      this.getExceptionalHoursDates(this.exceptionalHoursTabInput);
      this.haveExceptional = this.checkExceptionalHours();
    }

    this.facilityService.saveStatus(this.facility);
    this.isSuperParent = this.isSuperParent || this.isRushHours || this.isLAD;
    this.isLadFacility();
    this.partnerManageOpeningHours = this.facility.partnerManageOpeningHours;
    this.seeHoursActive = true;
    this.seeHours();
    this.setFacilityLabel();
    this.switchLabelUpdate();
  }

  checkExceptionalHours(): boolean {
    if (this.facility.slots) {
      for (const slot of this.facility.slots) {
        if (slot.exceptionalDay) {
          return true;
        }
      }
    }
    return false;
  }

  // This method checks the changes and is called before the method ngOnInit()
  ngOnChanges(simplesChanges: SimpleChanges): void {
    // called when the status parent is changed
    if (simplesChanges.parentIsActive !== undefined && !simplesChanges.parentIsActive.firstChange) {
      this.seeHoursActive = true;
      this.seeHours();
    }
    this.switchLabelUpdate();
  }

  /**
   * Check if the user has edit role or not
   */
  private checkUserRole(): void {
    this.userService.getUserInfo().subscribe((userInfos) => {
      if (userInfos) {
        this.isUserRestaurantManager = userInfos.roles.includes(Role.MANAGER);
      }
    });
  }

  /**
   * * Opens the edit facility modal to edit facility information
   */
  editFacility(): void {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.id = 'edit-facility-modal-component';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.height = '96%';
    dialogConfig.width = '94%';
    dialogConfig.data = {
      currentFacility: this.facility,
      facilityType: this.facilityType,
      facilityParentType: this.facilityParentType ? this.facilityParentType : this.facilityType,
    };
    this.matDialog
      .open(EditFacilityModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((updateFacility) => {
        if (updateFacility !== undefined) {
          this.facility = updateFacility;
          this.emitReloadFacilities();
          this.switchLabelUpdate();
        }
      });
  }

  /**
   * check if the current facility is LAD children
   */
  private isLadFacility(): void {
    if (
      this.facilityType === FacilityType.LAD_EXTERNE_UBER_EATS.valueOf() ||
      this.facilityType === FacilityType.LAD_EXTERNE_JUST_EAT.valueOf() ||
      this.facilityType === FacilityType.LAD_EXTERNE_DELIVEROO.valueOf() ||
      this.facilityType === FacilityType.LAD_EXTERNE_LYVEAT.valueOf()
    ) {
      this.isLAD = true;
    }
  }

  /**
   * check if the current facility is one of McDelivery children
   */
  private isMcDeliveryPartner(): boolean {
    return this.facility.reference === FacilityType.MC_DELIVERY_STUART;
  }

    /**
     *
     * @private
     */
    public isDrive(): boolean {
        return this.facilityType === FacilityType.MCDRIVE || this.facilityType === FacilityType.DRIVE_PIETON;
    }

  /**
   * Change message on the info pop in
   */
  getDataMessage(): string {
    if (this.isLAD || this.isMcDeliveryPartner()) {
      return `Le partenaire de livraison ${this.facilityService.retrieveFacilityLabel(this.facility)} est ${this.switchLabel}.`;
    } else if (this.isRushHours) {
      return `Les horaires de rush sont ${this.switchLabel}s.`;
    } else {
      return `Le canal de vente ${this.facilityService.retrieveFacilityLabel(this.facility)} est ${this.switchLabel}.`;
    }
  }
  /**
   * When switch button is triggered, call EDD WS to change Facility restaurant status.
   */
  facilityStatusChanged(): void {
    if (this.isUserRestaurantManager) {
      // we can't set status if we don't have userRestaurantManager role
      this.switchLabelUpdate();
      this.createToast();
      // Appel au Ws
      if (this.slotService.hasActiveOneDay(this.facility.slots) || (!this.isSuperParent && this.parentIsActive)) {
        this.facilityService
          .updateFacilityRestaurantActivation(this.restaurant.ref.toString(), this.facility.reference, this.facility.restaurantActivation)
          .subscribe(() => {
            this.changeShareStatus();
            this.emitChangeChildFacilityStatus();
            this.emitReloadFacilities();
          });
      } else {
        if (this.facility.restaurantActivation) {
          this.editFacility();
        }
      }
    }
  }

  /**
   * Create a toast to display on activation/inactivation
   */
  private createToast(): void {
    // we can edit only for "super" parent
    if (this.isSuperParent && this.facilityService.facilityActive(this.facility)) {
      CustomSnackbarComponent.actionText = 'Éditer';
    } else {
      CustomSnackbarComponent.actionText = '';
    }
    const toast = this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: this.getDataMessage(), // message to be shown
      duration: 5000, // duration in ms
    });
    // Ecoute sur le bouton action et exécute ce que l'on veut
    toast.onAction().subscribe(() => {
      this.editFacility();
      toast.dismiss();
    });
    if (!this.slotService.hasActiveOneDay(this.facility.slots) && this.isSuperParent) {
      CustomSnackbarComponent.subData = 'Renseignez des horaires pour valider l’activation';
    } else {
      CustomSnackbarComponent.subData = '';
    }
  }

  /**
   * Check if facility status is active or not
   * @returns true if nationalActivation AND restaurantActivation
   */
  public facilityActive(): boolean {
    return this.facilityService.facilityActive(this.facility) && (this.parentIsActive || this.isSuperParent);
  }

  /**
   *  Change seeHourStatus and set Label
   */
  seeHours(): void {
    this.seeHoursActive = !this.seeHoursActive;
    this.setLabelHour();
  }

  /**
   * set hour label
   */
  setLabelHour(): void {
    if (this.seeHoursActive) {
      this.seeHoursLabel = 'Voir moins';
    } else {
      switch (this.facilityType) {
        case FacilityType.MY_ROOM:
          this.seeHoursLabel = 'Horaires de la salle';
          break;
        case FacilityType.C_AND_C:
          this.seeHoursLabel = 'Horaires du Click & Collect';
          break;
        case FacilityType.MC_DELIVERY:
          this.seeHoursLabel = 'Horaires du LAD McDo+';
          break;
        default:
          this.seeHoursLabel = 'Voir les horaires';
      }
    }
  }

  /**
   * change the status label
   */
  switchLabelUpdate(): void {
    this.switchLabel = this.facilityActive() && this.parentIsActive ? 'actif' : 'inactif';
  }

  /**
   * Change the status of the facility saves in the facility.service
   */
  changeShareStatus(): void {
    this.facilityService.saveStatus(this.facility);
  }

  /**
   * Emit the change of status child facility ( for knowing if we have to desactivate my room automatically)
   */
  emitChangeChildFacilityStatus(): void {
    if (this.facilityType === FacilityType.COMPTOIR || this.facilityType === FacilityType.KIOSK) {
      this.changeChildfacilityStatus.emit(this.facility);
    }
  }

  /**
   * Emit to parent to reload Facilities
   */
  emitReloadFacilities(): void {
    this.reloadFacilities.emit(true);
  }

  /**
   * Check the dates to display for exceptional period
   * @param exceptionalHoursViewTabTmp the configured exceptional hours
   */
  getExceptionalHoursDates(exceptionalHoursViewTabTmp: ExceptionalHoursView[]): void {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.startDateExceptional = today;
    this.endDateExceptional = new Date();
    this.endDateExceptional.setHours(0, 0, 0, 0);
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 6);
    this.startConfigured = false;
    this.endConfigured = false;
    for (const exceptionalHousViewTmp of exceptionalHoursViewTabTmp) {
      for (const slot of exceptionalHousViewTmp.slots) {
        if (slot.facilityRef === this.facility.reference) {
          const start = new Date(exceptionalHousViewTmp.startDate);
          let end: Date;
          if (exceptionalHousViewTmp.endDate) {
            end = new Date(exceptionalHousViewTmp.endDate);
          } else {
            end = new Date(exceptionalHousViewTmp.startDate);
          }
          const diff = Math.abs(this.endDateExceptional.getTime() - start.getTime());
          const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
          this.calculateExceptionalHoursDates(start, end, maxDate, diffDays, today);
        }
      }
    }
  }

  private calculateExceptionalHoursDates(start: Date, end: Date, maxDate: Date, diffDays: number, today: Date): void {
    if (end >= this.endDateExceptional && (!this.endConfigured || diffDays === 1)) {
      if (!this.startConfigured) {
        // on ne prend que la 1ere date de début (date la + proche d'aujourdhui)
        this.startDateExceptional = start;
        this.startConfigured = true;
        if (this.startDateExceptional < today) {
          this.startDateExceptional = today;
        }
      }

      this.endDateExceptional = end;
      this.endConfigured = true;

      if (end > maxDate && this.startDateExceptional < maxDate) {
        this.endDateExceptional = maxDate;
      }
    }
  }

  private setFacilityLabel(): void {
    switch (this.facilityType) {
      case FacilityType.MCDRIVE:
        this.seeFacilityLabel = 'McDrive';
        break;
      case FacilityType.DRIVE_PIETON:
        this.seeFacilityLabel = 'Drive Piéton';
        break;
      default:
        this.seeFacilityLabel = 'CANAL DE VENTE';
    }
  }
}
