<div *ngIf="isLoading; else loader">
  <app-loader></app-loader>
</div>

<ng-template #loader>
  <div id="manual-injection">
    <div class="content">
      <div class="settings">
        <div class="left-side">
          <span class="settings-label">Click & Ready</span>
        </div>
        <div
          id="{{ restaurant.injectionConfig.nationalManualInjection ? 'switch-national-activate' : 'switch-national-desactivate' }}"
          class="right-side"
          [ngClass]="isUserRestaurantManager ? '' : 'right-side-noManager'"
        >
          <label class="switch-label">{{ getInjectionStatusLabel() }}</label>
          <div *ngIf="restaurant.injectionConfig.nationalManualInjection && isUserRestaurantManager" class="switch">
            <label>
              <input type="checkbox" [(ngModel)]="restaurant.injectionConfig.manualInjection" (change)="manualInjectionStatusChanged()" />
              <span class="slider"></span>
            </label>
          </div>
        </div>
      </div>

      <div id="clientInfos" *ngIf="!restaurant.injectionConfig.nationalManualInjection">
        <span class="infobulle red">i</span>
        <p>En raison d'une mesure nationale, l'activation n'est pas autorisée.</p>
      </div>
    </div>
  </div>
  <!-- add "or restaurant.injectionConfig.nationalManualInjection" ? -->
  <app-facility
    *ngIf="restaurant.injectionConfig.manualInjection && restaurant.injectionConfig.nationalManualInjection"
    [facility]="rushHoursFacility"
    [isSuperParent]="false"
    [isRushHours]="true"
    [facilityType]="facilityType.RUSH"
    [parentIsActive]="true"
    [facilityParentType]="facilityType.RUSH"
  ></app-facility>
</ng-template>
