export enum FacilityType {
  C_AND_C = '2',
  MCDRIVE = '5',
  DRIVE_PIETON = '48',
  MY_ROOM = '13',
  MC_DELIVERY = '44',
  MC_DELIVERY_STUART = '40',
  MC_DELIVERY_UBERDIRECT = '42',
  EAT_IN = '11',
  TAKE_OUT = '12',
  LAD_EXTERNE = '36',
  LAD_EXTERNE_UBER_EATS = '37',
  LAD_EXTERNE_DELIVEROO = '38',
  LAD_EXTERNE_JUST_EAT = '39',
  LAD_EXTERNE_LYVEAT = '41',
  KIOSK = '31',
  COMPTOIR = '47',
  RUSH = '14',
  MC_CAFE = '3',
  PTIT_DEJ = '26',
}
