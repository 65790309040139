<div class="{{ data.panelClass }}">
  <div *ngIf="data.topText" id="topText">
    {{ data.topText }}
  </div>

  <div id="title" [innerHtml]="data.title"></div>

  <div *ngIf="data.warningText" id="warning-text">
    {{ data.warningText }}
  </div>

  <div id="confirm-buttons">
    <button mat-raised-button id="confirm-button" (click)="closeModal(true)">{{ data.confirmText }}</button>
    <button *ngIf="data.applyText" mat-raised-button id="apply-button" (click)="applyToRestaurant()">{{ data.applyText }}</button>
    <button *ngIf="data.cancelText" mat-raised-button id="cancel-button" (click)="closeModal(false)">{{ data.cancelText }}</button>
  </div>
</div>
