<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="modal-header">
  <div class="modal-title">Commande</div>
  <a>
    <mat-icon id="close-modal-button" (click)="closeModal()">close</mat-icon>
  </a>
  <div class="order-reference">{{ this.orderService.getOrderTypeValue(selectedOrder?.type) }} {{ this.orderService.getOrderNumber(selectedOrder) }}</div>
</div>
<div class="order-summary">
  <div class="first-line">
    <span class="order-date"> Le {{ (selectedOrder.orderedDate | date: 'dd/MM/yyyy à HH:mm').replace(':', 'h') }} </span>
    <span class="order-price">
      {{ this.orderService.getTotalAmount(selectedOrder) | currency: 'EUR':'symbol':'1.2-2':'fr' }}
    </span>
  </div>
  <div class="second-line">
    <span class="order-type"> {{ this.orderService.getOrderTypeValue(selectedOrder?.type) }} {{ this.orderService.getLabel(selectedOrder) }} </span>
    <span class="order-payment">
      {{ paymentType[selectedOrder?.paymentType] }}
    </span>
  </div>
</div>

<div class="order-body">
  <app-order-error
    style="width: 95%; position: relative"
    *ngIf="orderStatusType.ERROR == selectedOrder?.status && orderError"
    [orderError]="orderError"
  ></app-order-error>
  <app-order-tracking class="order-tracking" *ngIf="!isHistoricalOrder() && isStuartOrder()" [selectedOrder]="selectedOrder"></app-order-tracking>
  <div *ngIf="orderType.C_AND_C === selectedOrder?.type" class="order-pod">
    <div class="order-pod-subtitle">Point de retrait</div>
    <app-order-pod style="width: 95%; position: relative" (podupdated)="setUpdateList()" [currentOrder]="selectedOrder"></app-order-pod>
  </div>
  <div class="order-header">
    <span class="subtitle"> Détails de la commande </span>
    <span class="order-products-quantity">
      {{ totalProductsNumber() }} {{ totalProductsNumber() > 1 ? 'produits' : 'produit' }}
      <div *ngIf="totalDishesNumber() > 0">
          {{ totalDishesNumber() }} {{ totalDishesNumber() > 1 ? 'éléments de vaisselle' : 'élément de vaisselle'}}
      </div>
    </span>
  </div>
  <app-order-item-list style="width: 95%" [items]="selectedOrder.items"></app-order-item-list>
</div>
