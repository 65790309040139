import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { OrderType } from '../../../models/order/order-type.enum';
import { Order } from '../../../models/order/order.model';
import { RestaurantSalesChannels } from '../../../models/restaurant/restaurant-sales-channels.model';

interface Data {
  key: string;
  y: number;
  color: string;
  parent: OrderType;
}

@Component({
  selector: 'app-graphique',
  templateUrl: './graphique.component.html',
  styleUrls: ['./graphique.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GraphiqueComponent implements OnInit, OnChanges {
  @Input() orders: Order[];
  @Input() newOrders: number;
  @Input() pageLevel: number;
  @Input() isSubCategory: boolean;
  @Input() salesChannel: RestaurantSalesChannels;

  @Output() isSubCategoryPage: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() filteredOrdersChange = new EventEmitter<Order[]>();
  @Output() refreshOrdersEvent = new EventEmitter<boolean>();

  // Variables to create the chart
  options;
  data;

  // filter by order type (C&C/McDelivery/LAD)
  orderTypeFilter = 'All';
  // filter by POD (C&C) or partner (McDelivery/LAD)
  secondLevelFilter = '';

  // Different parameters
  CAndCOrders: Order[];
  McDeliveryOrders: Order[];
  LADOrders: Order[];
  orderTotal: number;
  todayDate: Date;

  constructor() {}

  ngOnInit(): void {}

  private initChart(): void {
    this.orderTotal = this.orders.length;
    // Different channels
    this.CAndCOrders = this.orders.filter((order) => {
      return order.type === OrderType.C_AND_C;
    });
    this.McDeliveryOrders = this.orders.filter((order) => {
      return order.type === OrderType.MC_DELIVERY;
    });
    this.LADOrders = this.orders.filter((order) => {
      return order.type === OrderType.LAD;
    });

    this.createChart();
  }

  /**
   * Check if there is changes for orders from parent
   */
  ngOnChanges(changes: SimpleChanges): void {
    const subPageItem: SimpleChange = changes.isSubCategory;
    if (subPageItem) {
      this.isSubCategory = subPageItem.currentValue;
    }

    const currentItem: SimpleChange = changes.orders;
    if (currentItem && currentItem.currentValue) {
      this.orders = changes.orders.currentValue;
      if (!this.isSubCategory) {
        this.orderTypeFilter = 'All';
      }
      this.initChart();
    }
  }

  private createChart(): void {
    this.todayDate = new Date();
    this.createOption();
    this.createData(this.orderTypeFilter);
  }

  private createOption(): void {
    this.options = {
      chart: {
        type: 'pieChart',
        height: 450,
        margin: {
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        },
        donut: true,
        showLabels: false,
        cornerRadius: 10,
        donutRatio: 0.62,
        tooltip: false,
        x(d): any {
          return d.key;
        },
        y(d): any {
          return d.y;
        },
        pie: {
          startAngle(d): number {
            return d.startAngle / 2 - Math.PI / 2;
          },
          endAngle(d): number {
            return d.endAngle / 2 - Math.PI / 2;
          },
        },
        showLegend: false,
      },
    };
  }

  private createData(channel: string): void {
    this.data = [];
    if (channel === 'All') {
      if (this.salesChannel.clickAndCollectEnabled) {
        this.data.push({
          key: 'C&C',
          y: this.CAndCOrders.length,
          color: 'var(--candc-color)',
          parent: '',
        });
      }
      if (this.salesChannel.deliveryEnabled) {
        this.data.push({
          key: 'LAD McDo+',
          y: this.McDeliveryOrders.length,
          color: 'var(--mcdelivery-color)',
          parent: '',
        });
      }
      if (this.salesChannel.externalDeliveryEnabled) {
        this.data.push({
          key: 'LAD',
          y: this.LADOrders.length,
          color: 'var(--lad-color)',
          parent: '',
        });
      }
    } else if (channel === 'C&C') {
      const colors: string[] = ['#411B61', '#8154A8', '#B992Db', '#C968DA', '#CC37AE', '#AC1056', '#750C1B'];
      this.data = this.createChartDataFromList(colors, channel);
    } else if (channel === 'LAD McDo+') {
      const colors = ['#447568', 'var(--mcdelivery-color)', '#6EA3C0'];
      this.data = this.createChartDataFromList(colors, channel);
    } else if (channel === 'LAD') {
      const colors = ['#BE5400', 'var(--lad-color)', '#F3A810', '#D4B893', '#EE924F'];
      this.data = this.createChartDataFromList(colors, channel);
    }

    this.data.sort((data1, data2) => {
      return data1.y < data2.y ? 1 : -1;
    });
  }

  private createChartDataFromList(colors: string[], parentChannel: string): Data[] {
    const dataToReturn = [];
    const labelList: string[] = [];

    this.orderTotal = this.orders.length;

    this.orders.forEach((element) => {
      const label = this.getLabel(element);
      if (labelList.includes(label)) {
        const i = labelList.indexOf(label);
        dataToReturn[i].y += 1;
      } else {
        labelList.push(label);
        dataToReturn.push({
          key: label,
          y: 1,
          color: colors[labelList.length - 1],
          parent: parentChannel,
        });
      }
    });
    return dataToReturn;
  }

  getLabel(order: Order): string {
    if (order.type === OrderType.C_AND_C) {
      return this.isLabelNull(order.podLabel) ? 'Inconnu' : order.podLabel;
    } else {
      return this.isLabelNull(order.partnerLabel)
        ? 'Inconnu'
        : order.partnerLabel[0].toUpperCase() + order.partnerLabel.substr(1).toLowerCase();
    }
  }

  /**
   * Check if the given label is null or empty
   * @param label Label to check
   * @returns True if null or empty, false otherwise
   */
  private isLabelNull(label: string): boolean {
    return label == null || label === '' || label === undefined;
  }

  /**
   * On click on a filter in the graph legend.
   */
  goToChannelDetails(data: Data): void {
    if (this.pageLevel >= 3) {
      return;
    }
    let pageLevel;
    let otherFilter;

    if (this.isLabelNull(data.parent)) {
      this.orderTypeFilter = data.key;
      this.secondLevelFilter = '';
      pageLevel = 1;
    } else {
      this.orderTypeFilter = data.parent;
      this.secondLevelFilter = data.key;
      otherFilter = this.secondLevelFilter !== 'Inconnu' ? this.secondLevelFilter : undefined;
      pageLevel = 2;
      if ('C&C' === this.orderTypeFilter) {
        this.orders = this.orders.filter((order) => order.podLabel === otherFilter);
      } else {
        this.orders = this.orders.filter((order) => order.partnerLabel.toLowerCase() === otherFilter.toLowerCase());
      }
    }

    this.orders = this.orders.filter((order) => order.type === this.mapToOrderType(this.orderTypeFilter));

    const pageName = this.isLabelNull(this.secondLevelFilter) ? this.orderTypeFilter : this.secondLevelFilter;
    this.isSubCategoryPage.emit([pageName, pageLevel]);
    this.filteredOrdersChange.emit(this.orders);
  }

  /**
   * Informs parent that refresh button has been clicked.
   */
  refreshOrders(): void {
    this.refreshOrdersEvent.emit(true);
  }

  private mapToOrderType(orderTypeString: string): OrderType {
    switch (orderTypeString) {
      case 'C&C':
        return OrderType.C_AND_C;
      case 'LAD McDo+':
        return OrderType.MC_DELIVERY;
      case 'LAD':
        return OrderType.LAD;
      default:
        return null;
    }
  }
}
