import {Injectable} from '@angular/core';
import {MatDialogConfig} from "@angular/material/dialog";

@Injectable({
    providedIn: 'root',
})
export class DialogConfigUtilsService {

    public getDialogConfig(dialogId: string,
                           title: string,
                           confirmText: string,
                           cancelText: string,
                           panelClass: string,
                           width: string,
                           showWarningText: boolean,
                           topText?: string,
                           applyText?: string): MatDialogConfig {
        const dialogConfig = new MatDialogConfig();
        // The user can't close the dialog by clicking outside its body
        dialogConfig.disableClose = true;
        dialogConfig.id = dialogId;
        dialogConfig.maxWidth = '100vw';
        dialogConfig.height = '224px';
        dialogConfig.width = width;
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = panelClass;
        const warningText =
            'Vérifiez les horaires d’ouverture de vos points de retrait. Au moins un point de retrait doit être ouvert sur les horaires du Click & Collect.';
        if (applyText !== null) {
            if (showWarningText) {
                dialogConfig.data = {title, confirmText, cancelText, panelClass, topText, warningText, applyText};
            } else {
                dialogConfig.data = {title, confirmText, cancelText, panelClass, topText, applyText};
            }
        } else {
            if (showWarningText) {
                dialogConfig.data = {title, confirmText, cancelText, panelClass, topText, warningText};
            } else {
                dialogConfig.data = {title, confirmText, cancelText, panelClass, topText};
            }
        }
        return dialogConfig;
    }
}
