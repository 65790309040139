import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Facility } from '../../../models/facility/facility.model';
import { FacilityType } from '../../../models/facility/facilityType.enum';
import { Restaurant } from '../../../models/restaurant/restaurant.model';
import { FacilityService } from '../../../service/facility/facility.service';
import { PersistanceService } from '../../../service/persistance/persistance.service';
import { RestaurantService } from '../../../service/restaurant/restaurant.service';
import { ConfirmModalComponent } from '../../common/confirm-modal/confirm-modal.component';
import { Role } from '../../../models/role.enum';
import { UserService } from '../../../service/user/user.service';
import { ErrorService } from '../../../service/error/error.service';
import { ConsoleLoggerService } from '../../../service/logger/console-logger.service';

@Component({
  selector: 'app-restaurant-manual-injection',
  templateUrl: './restaurant-manual-injection.component.html',
  styleUrls: ['./restaurant-manual-injection.component.scss'],
})
export class RestaurantManualInjectionComponent implements OnInit {
  @Input() restaurant: Restaurant;
  @Output() refreshRestaurant: EventEmitter<any> = new EventEmitter();
  @Output() hideTitle = new EventEmitter<boolean>();
  rushHoursFacility: Facility;
  isLoading = true;
  facilityType: typeof FacilityType = FacilityType;
  isUserRestaurantManager = false;

  constructor(
    private readonly router: Router,
    private readonly persistanceService: PersistanceService,
    private readonly restaurantService: RestaurantService,
    public matDialog: MatDialog,
    private readonly facilityService: FacilityService,
    private readonly userService: UserService,
    private readonly errorService: ErrorService,
    private readonly CONSOLE_LOGGER: ConsoleLoggerService
  ) {}

  ngOnInit(): void {
    this.getRushHours();
    this.emitInjectionTitle();
    this.checkUserRole();
  }

  /**
   * Check if the user has edit role or not
   */
  private checkUserRole(): void {
    this.userService.getUserInfo().subscribe((userInfos) => {
      if (userInfos) {
        this.isUserRestaurantManager = userInfos.roles.includes(Role.MANAGER);
      }
    });
  }

  /**
   * Get the status label based on the national and local manual injection status.
   */
  getInjectionStatusLabel(): string {
    return this.restaurant.injectionConfig.nationalManualInjection && this.restaurant.injectionConfig.manualInjection ? 'Actif' : 'Inactif';
  }

  /**
   * Call EDD WS to update the manual injection flag for the restaurant.
   */
  manualInjectionStatusChanged(): void {
    this.restaurantService
      .updateRestaurantManualInjection(this.restaurant.ref.toString(), this.restaurant.injectionConfig.manualInjection)
      .subscribe(
        (restaurant: Restaurant) => {
          this.restaurant = restaurant;
        },
        (err) => {
          this.CONSOLE_LOGGER.error('Error during update', err);
          this.errorService.manageError(err);
          // the update is not effective, reset old status value
          this.restaurant.injectionConfig.manualInjection = !this.restaurant.injectionConfig.manualInjection;
          this.openErrorModal();
        }
      );
  }

  /**
   * Open an error popup when an error occurred during manual injection update.
   */
  private openErrorModal(): void {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.id = 'confirm-modal';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.height = '224px';
    dialogConfig.width = '94%';
    dialogConfig.autoFocus = false;
    dialogConfig.panelClass = 'update-error';

    const title = "En raison d'une erreur technique, vos paramètres n'ont pas été enregistrés. Veuillez réessayer ultérieurement.";
    const confirmText = 'QUITTER';

    dialogConfig.data = { title, confirmText, panelClass: dialogConfig.panelClass };
    this.matDialog
      .open(ConfirmModalComponent, dialogConfig)
      .afterClosed()
      .subscribe(() => {
        // informs parent (restaurant-settings) to GET restaurant from WS to get the new injection status
        this.refreshRestaurant.emit();
      });
  }

  showFaq(): void {
    this.persistanceService.set('faq_return_path', this.router.url);
    this.router.navigateByUrl('/faq');
  }

  getRushHours(): void {
    this.facilityService.getRestaurantFacilityByRef(this.restaurant.ref, FacilityType.RUSH.valueOf()).subscribe(
      (facility) => {
        this.rushHoursFacility = facility;
        this.facilityService.saveStatus(this.rushHoursFacility);
        this.isLoading = false;
      },
      (error) => {
        this.CONSOLE_LOGGER.error('Error while getting rush hours facility');
        this.errorService.manageError(error);
        this.isLoading = false;
      }
    );
  }

  /**
   * Emit to parent to hide injection title
   */
  emitInjectionTitle(): void {
    if (!this.restaurant.injectionConfig.nationalManualInjection) {
      this.hideTitle.emit(true);
    }
  }

  public facilityActive(): boolean {
    return this.facilityService.facilityActive(this.rushHoursFacility);
  }
}
